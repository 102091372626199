import { useRouter } from 'next/router'

import { deviceType } from 'utils/deviceType'
import { useSatuEventTracker } from 'utils/satuEvent'
import AndroidHandler from 'utils/androidHandler'
import { useAuth } from 'utils/auth'
import { APP_DEVICE_ID_STORAGE_KEY } from 'configs/android'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'

import type { TSETrackInfoTerkiniInformationPage } from 'types/info-terkini'
import type { TSETrackHomeBannersActivities } from 'types/home-banner'
import type {
  TSETrackDaftarKurikulumMerdekaClickActivities,
  TSETrackDaftarKurikulumMerdekaLoadedActivities,
  TSETrackEventDefaultAtributes,
} from 'types/pendaftaran-ikm'
import type { TSETrackHomeClickProfileEntryActivities } from 'types/profile'

const SATU_EVENT_NAME = 'guru_core_pwa_event_tracker_v1'
const TRACKER_EVENT_NAME = 'GURU_CORE_APP'
const TRACKER_SCREEN_NAME = 'HOMEPAGE'
const DEFAULT_TIMEOUT = 5000 // 5 seconds

export const useSETrackEvent = () => {
  const [, , , getAppDeviceIdValue] = useLocalStorage(
    APP_DEVICE_ID_STORAGE_KEY,
    null
  )

  const router = useRouter()
  const appDeviceID = getAppDeviceIdValue()
  const hasAppDeviceID = Boolean(appDeviceID)
  const androidHandler = AndroidHandler()
  const { session } = useAuth()
  const { SETrackEvent, SETransformAttrs } = useSatuEventTracker()

  const EVENT_TRIGGER_MAPPING = {
    '/home': 'HOMEPAGE',
    '/ide-praktik': 'IDE_PRAKTIK_EXPLORE_PAGE',
    '/learning-group': 'CLASS_SETTINGS',
    '/akun': 'ACCOUNT',
    '/info-terkini': 'INFORMATION_PAGE',
    '/pencarian': 'SEARCH_PAGE',
  }

  const getEventTrigger = () => EVENT_TRIGGER_MAPPING?.[router.route] ?? ''

  const SETrackEventDefaultAtributes: TSETrackEventDefaultAtributes = {
    screen_name: TRACKER_SCREEN_NAME,
    is_login: [Boolean(session), 'bool'],
    is_webview: [androidHandler.enabled, 'bool'],
    email: session?.user?.email ?? null,
  }

  const SETrackComponent = (
    activity_type: string,
    additionalAttributes?: Record<string, any>
  ): void => {
    SETrackEvent({
      eventName: SATU_EVENT_NAME,
      eventParams: {
        activity_type,
        event_name: TRACKER_EVENT_NAME,
        user_id: session?.user?.id,
        ...(hasAppDeviceID ? { device_id: appDeviceID } : {}),
        attributes: SETransformAttrs({
          ...SETrackEventDefaultAtributes,
          event_trigger: TRACKER_SCREEN_NAME,
          ...additionalAttributes,
        }),
      },
      timeout: DEFAULT_TIMEOUT,
      keepAlive: true,
    })
  }

  const SETrackPendaftaranIKMLoaded = (
    activityType: TSETrackDaftarKurikulumMerdekaLoadedActivities
  ): void => SETrackComponent(activityType)

  const SETrackPendaftaranIKMClicked = (
    activityType: TSETrackDaftarKurikulumMerdekaClickActivities
  ): void => SETrackComponent(activityType)

  const SETrackBanners = (
    activityType: TSETrackHomeBannersActivities,
    additionalAttributes: Record<string, any>
  ): void => {
    const { num_components: numComponents } = additionalAttributes
    SETrackComponent(activityType, {
      ...additionalAttributes,
      num_components: [numComponents, 'int'],
    })
  }

  // Tracker for Profil Guru entry point on Homepage
  const SETrackClickProfileEntryPoint = (
    activityType: TSETrackHomeClickProfileEntryActivities
  ): void => {
    SETrackComponent(activityType, {
      event_trigger: getEventTrigger(),
      screen_name: getEventTrigger(),
      email: session?.user?.email ?? null,
    })
  }

  const SETrackClickViewProfile = (): void => {
    SETrackComponent('CLICK_VIEW_PROFILE', {
      event_trigger: 'ACCOUNT',
      screen_name: 'ACCOUNT',
      email: session?.user?.email ?? null,
    })
  }

  const SETrackClickInformationPage = (
    activity_type: TSETrackInfoTerkiniInformationPage
  ): void => {
    SETrackComponent(activity_type, {
      event_trigger: 'guru.kemdikbud.go.id/home',
      screen_name: 'ACCOUNT',
      page_name: 'http://guru.kemdikbud.go.id/info-terkini',
      user_email: session?.user?.email ?? null,
      device: deviceType(),
      page_element: null,
    })
  }

  // Content Collection V3
  const SETrackCCV3Loaded = (url: string): void => {
    SETrackComponent('CC_HOMEPAGE_OPEN', {
      url,
    })
  }

  const SETrackCCV3ClickItem = (url: string, topicName: string): void => {
    SETrackComponent('CC_HOMEPAGE_CLICK_CONTENT_COLLECTION', {
      url,
      topic_name: topicName,
    })
  }

  const SETrackCCV3ClickMoreButton = (url: string): void => {
    SETrackComponent('CC_HOMEPAGE_CLICK_LIHAT_LEBIH_BANYAK', {
      url,
    })
  }

  const SETrackEventHomePage = (activityType: string): void => {
    SETrackComponent(activityType)
  }

  // Tracker for Bottom Navigation Bar
  const SETrackClickBottomNavbar = (activityType: string): void => {
    SETrackComponent(activityType, {
      event_trigger: getEventTrigger(),
      screen_name: getEventTrigger(),
    })
  }

  const SETrackClickSearchBar = (): void => {
    SETrackComponent('CLICK_HOMEPAGE_SEARCH_BAR', {
      event_trigger: getEventTrigger(),
      screen_name: getEventTrigger(),
    })
  }

  const SETrackLMSConnect = (activityType: string): void => {
    SETrackComponent(activityType, {
      event_trigger: getEventTrigger(),
      screen_name: getEventTrigger(),
    })
  }

  return {
    SETrackPendaftaranIKMLoaded,
    SETrackPendaftaranIKMClicked,
    SETrackBanners,
    SETrackClickProfileEntryPoint,
    SETrackClickViewProfile,
    SETrackClickInformationPage,

    // Content Collection V3
    SETrackCCV3Loaded,
    SETrackCCV3ClickItem,
    SETrackCCV3ClickMoreButton,

    // Homepage V2
    SETrackEventHomePage,
    SETrackClickBottomNavbar,
    SETrackClickSearchBar,

    // LMS Connect
    SETrackLMSConnect,
  }
}
