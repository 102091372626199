import React from 'react'
import { useRouter } from 'next/router'
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary'
import Image from 'next/image'

import { Spinner } from '@wartek-id/spinner'
import { Button } from '@wartek-id/button-v1'
import { Text } from '@wartek-id/text'
import { historyUtils } from '@wartek-id/fe-toolbox'
import { Icon } from '@wartek-id/icon-v2'

import { useAuth } from 'utils/auth'
import { MILKvKeys, useGetKeyPairValue } from 'api/microlearning/useAPIKeyValue'
import { CustomErrorFallback } from 'components/ErrorBoundary'
import { CustomError, SessionError } from 'utils/customError'
import { useAPILMSConnect } from 'api/microlearning/useAPILMS'
import DescriptionList from 'components/DescriptionList'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { LayoutWithHeader as Layout } from 'components/Layout'
import AndroidHandler from 'utils/androidHandler'
import { useSETrackEvent } from 'app/Homepage/hooks/useSETrackEvent'

import type { FallbackProps } from 'react-error-boundary'
import type { NextRouter } from 'next/router'
import type { ResponseLMSConnect } from 'types/microlearning'

class LMSConnectUnavailable extends CustomError {
  retry: boolean
  constructor(apiPath: string, originalError: any, retry?: boolean) {
    let title = 'Fitur pelatihan LMS tidak tersedia'
    let description = 'Mohon tunggu info lebih lanjut mengenai fitur ini'
    super({ title, description, apiPath, originalError })
    this.retry = retry
  }
}

class LMSConnectServiceError extends CustomError {
  retry: boolean
  constructor(apiPath: string, originalError: any, retry?: boolean) {
    let title = 'Fitur pelatihan LMS mengalami gangguan'
    let description = 'Mohon tunggu info lebih lanjut mengenai fitur ini'
    super({ title, description, apiPath, originalError })
    this.retry = retry
  }
}

class UserNotRegisteredOnLMS extends CustomError {
  retry: boolean
  constructor(apiPath: string, originalError: any, retry?: boolean) {
    let title = 'Anda tidak terdaftar sebagai peserta pelatihan LMS'
    let description =
      'Apabila anda merasa terdaftar sebagai peserta pelatihan LMS, silahkan hubungi bantuan'
    super({ title, description, apiPath, originalError })
    this.retry = retry
  }
}

class UserDidNotCameFromLMSOpenID extends CustomError {
  retry: boolean
  constructor(apiPath: string, originalError: any) {
    let title = 'Anda belum terhubung dengan fitur pelatihan LMS'
    let description =
      'Tekan tombol dibawah ini untuk menghubungkan Akun belajar.id anda dengan fitur pelatihan LMS'
    super({ title, description, apiPath, originalError })
  }
}

const LMSConnectController = () => {
  const router = useRouter()
  const { query } = router
  const { session } = useAuth()
  const featureFlag = useGetKeyPairValue(MILKvKeys.FeatureFlag)
  const handleError = useErrorHandler()
  const { SETrackLMSConnect } = useSETrackEvent()
  const OpenIDConnection = useAPILMSConnect(
    {
      client_id: `${query.client_id}`,
      response_type: `${query.response_type}`,
      redirect_uri: `${query.redirect_uri}`,
      scope: `${query.scope}`,
      state: `${query.state}`,
      guru_token: session.guruToken,
    },
    {
      enabled:
        // @ts-ignore
        !!featureFlag?.data?.data?.lmsConnect &&
        featureFlag.isSuccess &&
        router.isReady &&
        Boolean(query.client_id) &&
        Boolean(query.response_type) &&
        Boolean(query.redirect_uri) &&
        Boolean(query.scope) &&
        Boolean(query.state),
      onError: (error) => {
        // @ts-ignore
        const originalError = error?.originalError
        if (originalError.response.status === 401) {
          return handleError(
            new UserNotRegisteredOnLMS(
              null,
              new Error('Page is not available'),
              false
            )
          )
        }
        return handleError(
          new LMSConnectServiceError(
            null,
            new Error('Page is not available'),
            false
          )
        )
      },
      onSuccess: (data: ResponseLMSConnect) => {
        SETrackLMSConnect('NAVIGATE_TO_CANVAS_LMS')

        router.replace(
          `${query.redirect_uri}?client_id=${query.client_id}&state=${query.state}&code=${data.code}`
        )
      },
    }
  )

  if (
    router.isReady &&
    !Boolean(query.client_id) &&
    !Boolean(query.response_type) &&
    !Boolean(query.redirect_uri) &&
    !Boolean(query.scope) &&
    !Boolean(query.state)
  ) {
    handleError(
      new UserDidNotCameFromLMSOpenID(null, new Error('invalid origin'))
    )
  }

  // @ts-ignore
  if (featureFlag.isSuccess && !featureFlag?.data?.data?.lmsConnect) {
    handleError(
      new LMSConnectUnavailable(null, new Error('Page is not available'), false)
    )
  }

  if (router.isReady && !Boolean(session.guruToken)) {
    handleError(new SessionError(null, new Error('Not Authorized')))
  }

  return (
    <div className="flex justify-center py-4">
      {OpenIDConnection.isLoading ? (
        <Spinner size="md" />
      ) : (
        <Text>Mengalihkan halaman anda... mohon menunggu</Text>
      )}
    </div>
  )
}

const renderCustomError = ({
  error,
  resetErrorBoundary,
  redirectToCanvasLogin,
  router,
  email = '-',
  userId,
}: FallbackProps & {
  redirectToCanvasLogin: () => void
  router: NextRouter
  email?: string
  userId?: string
}) => {
  const androidHandler = AndroidHandler()

  if (error instanceof UserDidNotCameFromLMSOpenID) {
    return (
      <div className="grid grid-rows-3 w-full px-4 items-center">
        <div className="flex justify-center">
          <Image
            priority
            src={'/images/logo2x.png'}
            alt="Merdeka Mengajar"
            width={105}
            height={40}
          />
        </div>
        <Text as="div" className="w-full text-center" variant="heading-sm">
          Hubungkan Akun Anda dengan LMS
        </Text>
        <Button
          onClick={() => {
            redirectToCanvasLogin()
          }}
          fullWidth
        >
          Hubungkan Sekarang
        </Button>
      </div>
    )
  }

  if (error instanceof UserNotRegisteredOnLMS) {
    return (
      <Layout
        title=""
        onClickBack={() =>
          historyUtils.backToApp(() => {
            resetErrorBoundary()
            router.back()
          })
        }
      >
        <div className="px-4">
          <Text variant="heading-lg">
            Akun Anda saat ini belum terdaftar sebagai peserta pelatihan.
          </Text>

          <div className="mt-5 mb-8 p-4 border rounded">
            <Text>Anda login sebagai:</Text>
            <Text variant="action" className="flex gap-1 items-center">
              <Icon fontSize="sm">mail</Icon>
              {email}
            </Text>
            {userId && (
              <Text variant="eyebrow-md" className="flex gap-1 items-center">
                <Icon fontSize="sm">numbers</Icon>
                {userId}
              </Text>
            )}
          </div>

          <div>
            <Text className="mb-4">Berikut yang bisa Anda lakukan:</Text>
            <DescriptionList
              items={[
                {
                  action: {
                    label: 'Keluar dari PMM',
                    onClick: () => {
                      if (androidHandler.enabled) {
                        androidHandler.requestLogin()
                      } else {
                        const pathname = window.location.pathname || '/home'
                        window.location.href = `/logout?from=${pathname}&target=/login&error=SessionError`
                      }
                    },
                  },
                  description:
                    'Bila akun belajar ID Anda tidak sesuai dengan yang terdaftar, silakan keluar lalu masuk kembali dengan akun yang telah terdaftar mengikuti pelatihan',
                },
                {
                  action: {
                    label: 'Kembali ke PMM',
                    onClick: () => {
                      if (androidHandler.enabled) {
                        androidHandler.backToApp()
                      } else {
                        window.location.href = process.env.NEXT_PUBLIC_BASE_URL
                      }
                    },
                  },
                  description: 'Bila Anda tidak merasa mendaftar pelatihan',
                },
              ]}
            />
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <CustomErrorFallback
      error={error}
      resetErrorBoundary={resetErrorBoundary}
    />
  )
}

const LMSConnectOldPage = () => {
  const [userData] = useLocalStorage('app-login', false)
  const router = useRouter()
  const { SETrackLMSConnect } = useSETrackEvent()

  React.useEffect(() => {
    SETrackLMSConnect('VISIT_LMS_CONNECT_PAGE')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const redirectToCanvasLogin = () => {
    SETrackLMSConnect('CLICK_CONNECT_LMS_CANVAS_LOGIN_BUTTON')

    window.location.assign(
      `${process.env.NEXT_PUBLIC_LMS_URL}/login/openid_connect`
    )
  }
  return (
    <div className="min-h-full h-full bg-white flex flex-col">
      <div className="flex flex-grow items-center">
        <ErrorBoundary
          resetKeys={[router.asPath]}
          FallbackComponent={(args) =>
            renderCustomError({
              ...args,
              redirectToCanvasLogin,
              router,
              email: userData?.user?.email,
              userId: userData?.user?.id,
            })
          }
        >
          <div className="flex-1 px-4 mt-8 md:mt-0 mb-4">
            <div className="flex justify-center flex-row mb-4">
              <Image
                priority
                src={'/images/logo2x.png'}
                alt="Merdeka Mengajar"
                width={105}
                height={40}
              />
            </div>
            <Text as="div" className="w-full text-center" variant="heading-sm">
              Hubungkan Akun Anda dengan LMS
            </Text>
            <LMSConnectController />
          </div>
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default LMSConnectOldPage
