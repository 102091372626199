import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import LMSConnectOldPage from 'app/LMSConnect/LMSConnectOld'

const LMSConnect = () => {
  const { query } = useRouter()
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_LMS_CONNECT_REDIRECT === 'true') {
      const params = new URLSearchParams()
      Object.entries(query).forEach(([key, value]) => {
        params.append(key, String(value))
      })
      const queryParams =
        params.toString() !== '' ? `?${params.toString()}` : ''
      window.location.replace(
        `${process.env.NEXT_PUBLIC_LMS_LTI_URL}/lms-connect` + queryParams
      )
    }
  }, [query])

  if (process.env.NEXT_PUBLIC_LMS_CONNECT_REDIRECT !== 'true') {
    return <LMSConnectOldPage />
  }

  return (
    <div className="flex flex-col justify-center items-center h-full">
      Mengalihkan halaman..
    </div>
  )
}

export default LMSConnect
